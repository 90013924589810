import Layout from "components/layout";
import YoutubeVideoTranslation from "components/reusableComponents/youtube-video-translation";
import React from "react";
import SEO from "components/seo";

export default function index() {
  const title = "YouTube Video Translation Services | Tomedes";
  const description =
    "YouTube videos transcription, video translation and document translation services. We transcribe, translate and subtitle videos in over 100 languages. Get a quote now.";
  const keywords = "Translation, Translation services, Videos translation";
  const slug = "/youtube-video-translation";
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        slug={slug}
      />
      <YoutubeVideoTranslation />
    </Layout>
  );
}
