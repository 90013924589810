import React, { useEffect, useState } from "react";
import Email from "assets/images/Email.png";
import CallUs from "assets/images/CallUs.png";
import { useYoutube } from "hooks/Forms/useYoutube";
import { useForm } from "react-hook-form";
import Loader from "components/loader";
import ThankYouPopup from "components/popup/thankYouPopup";
import { useFetchLanguages } from "hooks/Quotes/useFetchLanguages";
import { useLocation } from "@reach/router";
import { Link } from "gatsby";
import getCookie from "utils/getCookie";
import { errorMessageEmail, patternEmail } from "utils/form-validation";
import Servicesicon from "components/homepage/translationServices/servicesicon";

function YoutubeBanner({
  backgroundCol,
  formOrangeHead,
  ftitle,
  midTitle,
  secondTitle,
  Subcontent,
  content,
  formWhiteHead,
}) {
  const [open, setOpen] = useState(false);
  const [checkBox, setCheckBox] = useState(false);
  const [imageupload, setImageUpload] = useState("");
  const useExperiment = (experimentId) => {
    const [variant, setVariant] = useState();

    useEffect(() => {
      (async () => {
        const intervalId = setInterval(() => {
          if (window.google_optimize !== undefined) {
            setVariant(window.google_optimize.get(experimentId));
            clearInterval(intervalId);
          }
        }, 100);
      })();
    }, []);
    return variant;
  };

  const variant = useExperiment("yWZT-4xlS9-M9AZuPD95Xg");

  const location = useLocation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { status, mutateAsync } = useYoutube();
  const { data } = useFetchLanguages();

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("video_url", data.linkname);
    formData.append("customer_email", data.email);
    formData.append("special_instructions", data.message);
    formData.append("translate_from", data.fromLang);
    formData.append("translate_to", data.toLang);
    formData.append("audio_file", imageupload);
    formData.append("phone_number", data.phoneNumber);
    formData.append("pagelink", location.href);
    formData.append("consent", data.consent);
    formData.append("source", getCookie("utm_source"));
    formData.append("utm_campaign", getCookie("utm_campaign"));
    formData.append("utm_agid", getCookie("utm_agid"));
    formData.append("utm_term", getCookie("utm_term"));
    formData.append("creative", getCookie("creative"));
    formData.append("device", getCookie("device"));
    formData.append("placement", getCookie("placement"));
    formData.append("utm_medium", getCookie("utm_medium"));

    // const postData = {
    //   name: data.name,
    //   business_email: data.email,
    //   description: data.message,
    //   translate_from: data.fromLang,
    //   translate_to: data.toLang,
    //   upload_file: imageupload,
    //   phone_number: data.phoneNumber,
    // };
    // console.log(formData);

    mutateAsync(formData).then((_) => {
      reset();
      setOpen(true);
    });
  };

  // const onFileUpload = () => {
  //   const formData = new FormData();
  //   formData.append("document", imageupload);
  //   mutateAsync(formData).then((response) => {
  //     if (response.success === 1) {
  //       setImageUpload("");
  //     }
  //   });
  // };

  const handleUploadImage = (e) => {
    const files = e.target.files[0];
    setImageUpload(files);
  };
  return (
    <section className="bg-gradient1  relative bg-desktop bg-cover   sm:px-0 pt-5 md:pt-16 pb-8 ">
      <div className="gradient px-2">
        {/* <div className="md-transparent md:bg-gradient-to-t from-[#00173A] to-transparent h-full "> */}
        <div className="max-w-7xl mx-auto flex flex-col">
          <div className="flex flex-col lg:flex-row gap-6 xl:gap-0 w-full px-3 sm:px-0  mx-auto justify-between items-start ">
            <div>
              <div className="pb-5">
                <ul className=" gap-8 lg:flex ">
                  <li className="flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12.445 17.6564C11.3529 18.1088 10.1821 18.3409 9 18.3393C7.81788 18.3409 6.64712 18.1087 5.555 17.6564C4.46287 17.204 3.4709 16.5402 2.63611 15.7032C1.79912 14.8685 1.13536 13.8765 0.682965 12.7844C0.230575 11.6922 -0.00152412 10.5215 7.53144e-06 9.33936C-0.00149947 8.15724 0.230611 6.98648 0.683 5.89435C1.13539 4.80222 1.79914 3.81026 2.63611 2.97546C3.4709 2.13849 4.46287 1.47474 5.555 1.02236C6.64712 0.569966 7.81788 0.337856 9 0.339363C10.1821 0.337831 11.3529 0.56993 12.445 1.02232C13.5372 1.47471 14.5291 2.13847 15.3639 2.97546C16.2009 3.81026 16.8646 4.80222 17.317 5.89435C17.7694 6.98648 18.0015 8.15724 18 9.33936C18.0015 10.5215 17.7694 11.6922 17.317 12.7844C16.8646 13.8765 16.2009 14.8685 15.3639 15.7032C14.5291 16.5402 13.5372 17.204 12.445 17.6564ZM14.1364 7.27575C14.4879 6.92427 14.4879 6.35443 14.1364 6.00295C13.7849 5.65148 13.2151 5.65148 12.8636 6.00295L8.09999 10.7666L6.03639 8.70295C5.68492 8.35148 5.11507 8.35148 4.7636 8.70295C4.41213 9.05442 4.41213 9.62427 4.7636 9.97574L7.4636 12.6757C7.81507 13.0272 8.38492 13.0272 8.73639 12.6757L14.1364 7.27575Z"
                        fill="#4285F4"
                      />
                    </svg>
                    <p className="text-base text-[#000C27] font-medium pl-2 lg:text-left text-center">
                      Available Online 24/7
                    </p>
                  </li>

                  <li className="flex items-center my-4 lg:my-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12.445 17.6564C11.3529 18.1088 10.1821 18.3409 9 18.3393C7.81788 18.3409 6.64712 18.1087 5.555 17.6564C4.46287 17.204 3.4709 16.5402 2.63611 15.7032C1.79912 14.8685 1.13536 13.8765 0.682965 12.7844C0.230575 11.6922 -0.00152412 10.5215 7.53144e-06 9.33936C-0.00149947 8.15724 0.230611 6.98648 0.683 5.89435C1.13539 4.80222 1.79914 3.81026 2.63611 2.97546C3.4709 2.13849 4.46287 1.47474 5.555 1.02236C6.64712 0.569966 7.81788 0.337856 9 0.339363C10.1821 0.337831 11.3529 0.56993 12.445 1.02232C13.5372 1.47471 14.5291 2.13847 15.3639 2.97546C16.2009 3.81026 16.8646 4.80222 17.317 5.89435C17.7694 6.98648 18.0015 8.15724 18 9.33936C18.0015 10.5215 17.7694 11.6922 17.317 12.7844C16.8646 13.8765 16.2009 14.8685 15.3639 15.7032C14.5291 16.5402 13.5372 17.204 12.445 17.6564ZM14.1364 7.27575C14.4879 6.92427 14.4879 6.35443 14.1364 6.00295C13.7849 5.65148 13.2151 5.65148 12.8636 6.00295L8.09999 10.7666L6.03639 8.70295C5.68492 8.35148 5.11507 8.35148 4.7636 8.70295C4.41213 9.05442 4.41213 9.62427 4.7636 9.97574L7.4636 12.6757C7.81507 13.0272 8.38492 13.0272 8.73639 12.6757L14.1364 7.27575Z"
                        fill="#4285F4"
                      />
                    </svg>
                    <p className="text-base text-[#000C27] font-medium pl-2 lg:text-left text-center">
                      1-Year Guarantee
                    </p>
                  </li>

                  <li className="flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12.445 17.6564C11.3529 18.1088 10.1821 18.3409 9 18.3393C7.81788 18.3409 6.64712 18.1087 5.555 17.6564C4.46287 17.204 3.4709 16.5402 2.63611 15.7032C1.79912 14.8685 1.13536 13.8765 0.682965 12.7844C0.230575 11.6922 -0.00152412 10.5215 7.53144e-06 9.33936C-0.00149947 8.15724 0.230611 6.98648 0.683 5.89435C1.13539 4.80222 1.79914 3.81026 2.63611 2.97546C3.4709 2.13849 4.46287 1.47474 5.555 1.02236C6.64712 0.569966 7.81788 0.337856 9 0.339363C10.1821 0.337831 11.3529 0.56993 12.445 1.02232C13.5372 1.47471 14.5291 2.13847 15.3639 2.97546C16.2009 3.81026 16.8646 4.80222 17.317 5.89435C17.7694 6.98648 18.0015 8.15724 18 9.33936C18.0015 10.5215 17.7694 11.6922 17.317 12.7844C16.8646 13.8765 16.2009 14.8685 15.3639 15.7032C14.5291 16.5402 13.5372 17.204 12.445 17.6564ZM14.1364 7.27575C14.4879 6.92427 14.4879 6.35443 14.1364 6.00295C13.7849 5.65148 13.2151 5.65148 12.8636 6.00295L8.09999 10.7666L6.03639 8.70295C5.68492 8.35148 5.11507 8.35148 4.7636 8.70295C4.41213 9.05442 4.41213 9.62427 4.7636 9.97574L7.4636 12.6757C7.81507 13.0272 8.38492 13.0272 8.73639 12.6757L14.1364 7.27575Z"
                        fill="#4285F4"
                      />
                    </svg>
                    <p className="text-base text-[#000C27] font-medium pl-2 lg:text-left text-center">
                      95,000 Business Customers
                    </p>
                  </li>
                </ul>
              </div>
              <h1 className="font-bold text-[32px] lg:text-46 font-opensans xs:font-secondary text-midBlue mb-2 w-full break-normal">
                {ftitle ? ftitle : ""}{" "}
                <span className="text-orange">{midTitle ? midTitle : ""}</span>{" "}
                {secondTitle ? secondTitle : ""}
              </h1>

              <p className="font-normal text-17 sm:text-20 leading-8 font-opensans text-midBlue mb-6 w-full md:w-full lg:w-[85%]">
                {content}
              </p>
              <p className="font-normal text-17 sm:text-20 leading-8 font-opensans text-midBlue mb-6 w-full md:w-full lg:w-[85%]">
                {Subcontent}
              </p>
              <div className="mt-12 inline-flex flex-col md:flex-row justify-start items-start md:items-center gap-7 font-opensans">
                <Link
                  to="/quote"
                  className="uppercase rounded-full bg-orange hover:bg-newOrange-100 text-white cursor-pointer text-17 font-semibold py-4 px-8 w-[253px] text-center transition-colors duration-300"
                >
                  {variant == "1" ? "START TRANSLATION" : " Translate Now"}
                </Link>
              </div>
            </div>
            <div>
              <form
                className="form border-white bg-locationFormBorder py-8 lg:justify-between justify-center h-full  mt-4 lg:mt-0  "
                onSubmit={handleSubmit(onSubmit)}
              >
                <ThankYouPopup open={open} setOpen={setOpen} />

                <div className="inputs flex flex-col items-center justify-center text-black ">
                  <div className="w-full lg:ml-[10px]">
                    <div className="grid grid-cols-1 lg:grid-cols-2 w-full px-4 md:px6 ">
                      {/* <label className="flex flex-col justify-center">
                  <span className="font-opensans text-xs font-semibold">
                    Translate from
                  </span>
                  <input
                    type="text"
                    placeholder="Email Address"
                    className="border-borderGray font-opensans border rounded lg:w-11/12 w-full md:w-[unset] pl-3  h-[34px] text-xs mb-6"
                  />
                </label> */}
                      <label htmlFor="" className="flex flex-col">
                        <span className="font-opensans text-xs font-semibold">
                          Translate from
                        </span>
                        <select
                          className="border-borderGray bg-white font-opensans border rounded lg:w-11/12 w-full md:w-[unset] pl-3  h-[34px] text-xs mb-6"
                          {...register("fromLang")}
                          id="fromLang"
                          name="fromLang"
                        >
                          <option disabled>Translate from</option>
                          {data
                            ? data?.data?.map(({ source_language }) => (
                                <option className="py-2 px-4 pr-10 mt-4 text-[#9e9e9e] bg-white w-full rounded">
                                  {source_language}
                                </option>
                              ))
                            : null}
                        </select>
                      </label>
                      {/* <label className="flex flex-col">
                  <span className="font-opensans text-xs font-semibold">
                    Translate to
                  </span>
                  <input
                    type="text"
                    placeholder="Email Address"
                    className="border-borderGray font-opensans lg:w-11/12 w-full border rounded  pl-3  h-[34px] text-xs  mb-6"
                  />
                </label> */}
                      <label htmlFor="" className="flex flex-col">
                        <span className="font-opensans text-xs font-semibold">
                          Translate to
                        </span>
                        <select
                          className="border-borderGray bg-white font-opensans border rounded lg:w-11/12 w-full md:w-[unset] pl-3  h-[34px] text-xs mb-6"
                          {...register("toLang")}
                          id="toLang"
                          name="toLang"
                        >
                          <option disabled>Translate to</option>
                          {data
                            ? data?.data?.map(({ source_language }) => (
                                <option className="py-2 px-4 pr-10 mt-4 text-[#9e9e9e] bg-white w-full rounded">
                                  {source_language}
                                </option>
                              ))
                            : null}
                        </select>
                      </label>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 w-full px-4 md:px6">
                      <label className="flex flex-col">
                        <span className="font-opensans text-xs font-semibold">
                          Link*
                        </span>
                        <input
                          type="text"
                          placeholder="Or share a link to your video"
                          className="border-borderGray font-opensans border rounded  lg:w-11/12 w-full md:w-[unset] pl-3  h-[34px] text-xs  mb-6"
                          {...register("linkname")}
                          id="linkname"
                          name="linkname"
                        />
                      </label>
                      <label className="flex flex-col">
                        <span className="font-opensans text-[12px] font-semibold">
                          Business Email Address*
                        </span>
                        <input
                          type="email"
                          placeholder="Email Address"
                          className="border-borderGray font-opensans border rounded  lg:w-11/12 w-full pl-3  h-[34px] text-xs"
                          id="email"
                          name="email"
                          {...register("email", {
                            required: true,
                            pattern: patternEmail,
                          })}
                        />
                        {errors.email && (
                          <span className="text-red py-1 pb-3 text-[12px]">
                            {errorMessageEmail}
                          </span>
                        )}
                      </label>
                    </div>

                    <div className="grid grid-cols-1 lg:grid-cols-2 w-full px-4 md:px6 ">
                      <label className="flex flex-col">
                        <span className="font-opensans text-[12px] font-semibold">
                          Phone Number
                        </span>
                        <input
                          name="phoneNumber"
                          type="tel"
                          placeholder="Enter Your Number Here"
                          className="border-borderGray font-opensans border rounded lg:w-11/12 w-full md:w-[unset] pl-3  h-[34px] text-xs  mb-6"
                          {...register("phoneNumber")}
                          id="phoneNumber"
                        />
                      </label>
                      <label className="flex flex-col">
                        <span className="font-opensans text-[12px] font-semibold">
                          Upload file / link
                        </span>
                        <input
                          name="document"
                          type="file"
                          placeholder="Share a link to"
                          className="border-borderGray font-opensans border rounded lg:w-11/12 w-full pl-3  h-[34px] text-xs mb-6"
                          // {...register("document", { required: true })}
                          id="document"
                          onChange={handleUploadImage}
                          value={imageupload}
                        />
                      </label>
                    </div>
                  </div>

                  <textarea
                    className="border-borderGray font-opensans border rounded pt-1 pl-3 w-[90%] text-xs"
                    cols="30"
                    rows="4"
                    placeholder="Tell us more about your needs"
                    {...register("message")}
                    id="message"
                    name="message"
                  ></textarea>
                  <div className="mt-3">
                    <div className="flex items-baseline gap-2">
                      <input
                        type="checkbox"
                        name="consent"
                        id="consent"
                        {...register("consent", {})}
                        onChange={() => setCheckBox((value) => !value)}
                      />
                      <p className="text-sm text-black font-opensans">
                        I agree to Tomedes'{" "}
                        <Link
                          to="/privacy"
                          className="text-orange hover:text-newOrange-100 underline transition-colors"
                        >
                          Privacy Policy.
                        </Link>{" "}
                        by submitting this form.
                      </p>
                    </div>
                    {errors.privacy_policy && (
                      <span className="text-red text-sm font-primary mb-[10px] mt-[-16px]">
                        Please tick above to agree terms & conditions
                      </span>
                    )}
                  </div>
                  <input type="hidden" id="zc_gad" name="zc_gad" value="" />

                  <button
                    href="/"
                    className="bg-orange py-2 text-center font-secondary font-extrabold mt-6 text-white block w-11/12"
                    // onClick={onFileUpload}
                  >
                    {status === "loading" ? <Loader /> : "SUBMIT"}{" "}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="w-full pt-[80px] pb-[60px]">
            <Servicesicon />
          </div>{" "}
        </div>
      </div>
    </section>
  );
}

export default YoutubeBanner;
