import React from "react";
import NewBanner from "./VideoTransFolder/newBanner";
import YoutubeBanner from "./YoutubeBanner";

export default function YoutubeVideoTranslation() {
  return (
    <div>
      <YoutubeBanner
        backgroundCol="bg-midBlue"
        ftitle="YouTube"
        midTitle="Video"
        secondTitle="translation and transcription"
        content="We transcribe, translate and subtitle videos in over 100 languages"
        Subcontent="Can't find what you're looking for? Contact us."
      />
      <div className="bg-darkBlue py-16">
        <h2 className="text-2xl md:text-5xl font-sans text-white text-center font-bold">
          Trusted by Business Customers Worldwide
        </h2>
        <ul className="pt-14 flex flex-col justify-center mx-auto gap-y-14 items-center px-4">
          <li className="pt-10">
            <img
              src="https://skins.tomedes.com/frontend/images/logos-video1.svg"
              alt=""
              className=" w-[50rem]"
            />
          </li>
          <li className="pt-10">
            <img
              src="https://skins.tomedes.com/frontend/images/logos-video2.svg"
              alt=""
              className=" w-[50rem]"
            />
          </li>
          <li className="pt-10">
            <img
              src="https://skins.tomedes.com/frontend/images/logos-video3.svg"
              alt=""
              className=" w-[50rem]"
            />
          </li>
        </ul>
      </div>
      <div className=" bg-midBlue pt-8">
        <h2 className="text-2xl md:text-5xl font-sans text-white text-center font-bold">
          YouTube and Video Translation Services
        </h2>

        <p className="text-white font-sans text-xl w-full px-4 sm:w-7/12 leading-loose flex mx-auto justify-center pt-12 ">
          It's not hard to understand why some YouTube channel owners and video
          creators seek out video translation services for their YouTube videos.
          Whether the request is for professional translation of subtitles or
          for initial dubbing processes, translation services are a must if a
          high number of YouTube views is a goal, and if your international
          audience is going to understand anything going on in the video. This
          is especially the case with languages that are very different from one
          another, such as English to Korean, and Korean to English
          translations.
        </p>
        <p className="text-white font-sans text-xl w-full px-4 sm:w-7/12 leading-loose flex mx-auto justify-center py-12 ">
          We recently assisted a client with a professional Korean translation
          of her YouTube video, which entailed completely revising the poor
          translation that had been machine generated, and made absolutely no
          sense. The video was a fairly short video, not a full-length movie,
          and because we did not need to worry about timing, pauses between
          subtitle lines, and other detailed processes within normal subtitling
          translation services, we delivered the completed Korean to English
          translation of the YouTube video within a day.
        </p>
      </div>
      <div
        className="parallaX flex items-center justify-center h-[350px] m-auto bg-fixed bg-center bg-no-repeat bg-cover"
        style="background-image: url(https://skins.tomedes.com/frontend/images/youtube-translation.jpg)"
      ></div>
      <div className=" bg-midBlue py-8">
        <h2 className="text-xl md:text-5xl font-sans text-white text-center font-bold">
          Professional Subtitle Translation vs.
        </h2>
        <br />
        <h2 className="text-xl md:text-5xl font-sans text-white text-center font-bold">
          Closed Caption Auto-Translation
        </h2>

        <p className="text-white font-sans text-xl w-full px-4 sm:w-7/12 leading-loose flex mx-auto justify-center pt-12 ">
          Though YouTube video translations are not as frequent as many other
          types of translation services we provide, this project got me thinking
          about other videos, which instead of using reliable subtitle
          translation services as part of the upload process, instead rely upon
          auto-translation of closed captions for sharing the video with
          audiences of other languages.
        </p>
        <p className="text-white font-sans text-xl w-full px-4 sm:w-7/12 leading-loose flex mx-auto justify-center py-12 ">
          The closed caption auto-translation method simply uses
          auto-translation on the closed captions of the original video
          language, which is Korean for the example video below. The closed
          captions are first selected and displayed in the original language,
          then the viewer selects "translate captions" and selects the language
          of choice. The captions are then auto-translated into that language -
          all of this happens post-production, relying solely upon MT.
        </p>
        <p className="text-white font-sans text-xl w-full px-4 sm:w-7/12 leading-loose flex mx-auto justify-center py-12 ">
          This method, while in some cases may be better than nothing, is pretty
          much worthless for many language pairs.Even on their blog, Google
          Translate marketing professionals and bloggers confront the issue of
          mistranslations head-on:
        </p>
        <p className="text-white font-sans text-xl w-full px-4 sm:w-7/12 leading-loose flex mx-auto justify-center py-12 ">
          Even on their blog, Google Translate marketing professionals and
          bloggers confront the issue of mistranslations head-on:
        </p>
        <p className="text-white font-sans text-xl w-full px-4 sm:w-7/12 leading-loose flex mx-auto justify-center py-12 ">
          "...there are still plenty of situations where mistakes will occur.
          Furthermore, when these technologies work together, mistakes can be
          amplified so you may find some captions that don’t make much sense." ~
          Andrew Gomez, Associate Product Marketing Manager, on GoogleTranslate
          blog.
        </p>
        <p className="text-white font-sans text-xl w-full px-4 sm:w-7/12 leading-loose flex mx-auto justify-center py-12 ">
          Again, when languages are more similar to each other, MT tools like
          Google Translate can be better than nothing at all. However, if your
          YouTube video is very important to you, and/or meant to reach as many
          viewers as possible, it is certainly necessary to acquire video
          translation services. Whether you go through the dubbing process, or
          simply have subtitles translated professionally, the number of YouTube
          views will most definitely be much greater than if you rely upon
          auto-translation.
        </p>
      </div>
    </div>
  );
}
